import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes instead of Switch
import Home from './Components/home';
import TeamPage from './Components/ourteam'; // Import your TeamPage component

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />  {/* Main Home page */}
                    <Route path="/ourteam" element={<TeamPage />} />  {/* TeamPage route */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
