import React from 'react';
import { Link } from 'react-router-dom';
import './teampage.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const TeamMember = ({ name, title, image, contributions }) => {
    return (
        <div className="container">
            <div className="team-member">
                <img src={image} alt={name} className="member-image" />
                <h3>{name}</h3>
                <p className="member-title">{title}</p>
                <p className="member-description">{contributions}</p>
            </div>

            {/* Footer */}
        </div>
    );
};

export default TeamMember;
