// TeamPage.js
import React from 'react';
import TeamMember from './teammember';
import './teampage.css'; // Import the CSS for styling

const TeamPage = () => {
    // Array of team members
    const teamMembers = [
        {
            name: 'Manish Yadav',
            title: 'Fullstack Developer',
            image: 'https://via.placeholder.com/150', // Replace with actual image URLs
            contributions: '',
        },
        {
            name: 'Rogith Goud',
            title: 'UI/UX Designedr',
            image: 'https://via.placeholder.com/150', // Replace with actual image URLs
            contributions: '',
        },
        {
            name: 'Datta sai Goud',
            title: 'Front End Developer',
            image: 'https://via.placeholder.com/150', // Replace with actual image URLs
            contributions: '',
        },
        {
            name: 'Mani Chenna Kesava Reddy',
            title: 'Frontend Developer',
            image: 'https://via.placeholder.com/150',
            contributions: '',
        },
    ];

    return (
        <div className="team-page">
            <h1>Meet Our Team</h1>
            <p>Just take a look - each member of the team is watching your every gesture and will hear your every whisper.</p>
            <div className="team-grid">
                {teamMembers.map((member, index) => (
                    <TeamMember
                        key={index}
                        name={member.name}
                        title={member.title}
                        image={member.image}
                        contributions={member.contributions}
                    />
                ))}
            </div>
        </div>
    );
};

export default TeamPage;
