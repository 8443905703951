import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './home.css'; // Importing the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Home = () => {
    return (
        <div className="container">
            {/* Header */}
            <header className="header">
                <img src="/Logo clothery.svg" alt="Company Logo" className="logo" />
                <nav className="nav">
                    <a href="#home">Home</a>
                    <a href="#how-it-works">How it works</a>
                    <div className="dropdown">
                        <span>Services</span>
                        <div className="dropdownContent">
                            <a href="#pickup">Pickup</a>
                            <a href="#drop-off">Drop-off</a>
                            <a href="#laundry">Laundry</a>
                        </div>
                    </div>
                    <a href="#locations">Locations</a>
                    <a href="#contact">Contact us</a>
                </nav>
            </header>

            {/* Main Body - Coming Soon */}
            <main className="main">
                <div className="logoSection">
                    {/* <p>Your clothes' new favorite destination for a fresh, clean start!</p> */}
                    <img src="Comming_soon.svg" alt="Company Logo" className="logobody" />
                </div>
            </main>

            {/* Footer */}
            <footer className="footer">
                <div className="logoSection">
                    <img src="/Clothery text.svg" alt="Company Logo" className="logofooter" />
                </div>
                <div className="linkSection">
                    <div>
                        <h4>About us</h4>
                        <a href="#about">About us</a>
                        <a href="#creators">Creators</a>
                        <a href="#philosophy">Philosophy</a>
                    </div>
                    <div>
                        <h4>Company</h4>
                        <Link to="/ourteam">Our team</Link> {/* Use Link for navigation */}
                        <a href="#terms">Terms</a>
                        <a href="#how-it-works">How it works</a>
                    </div>
                    <div>
                        <h4>Services</h4>
                        <a href="#pickup">Pickup</a>
                        <a href="#drop-off">Drop-off</a>
                        <a href="#laundry">Laundry</a>
                    </div>
                    <div>
                        <h4>Check us out</h4>
                        <div className="social-media">
                            <a href="#facebook">
                                <FontAwesomeIcon icon={faFacebookF} style={{ marginRight: '8px' }} />
                            </a>
                            <a href="#instagram">
                                <FontAwesomeIcon icon={faInstagram} style={{ marginRight: '8px' }} />
                            </a>
                            <a href="#linkedin">
                                <FontAwesomeIcon icon={faLinkedinIn} style={{ marginRight: '8px' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Home;
